<template>
  <div>
    <div class="mainHeader containerWidthMin"> 
      <mainHeader></mainHeader>
    </div>
    <router-view />
  </div>
</template>

<script>
import mainHeader from '@/components/mainHeader.vue'
export default {
  name: 'mainWebsite',
  components: {
    mainHeader
  }
};
</script>

<style scoped>
.mainHeader{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
</style>
