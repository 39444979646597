<template>
  <div>
    <el-dialog
      :visible.sync="show"
      :width="width"
      :modal-append-to-body="false"
      :before-close="()=>{show=false}">
      <slot></slot>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'mainDialog',
  props: {
    // modelValue: {
    //   type: Object,
    //   default: () => {
    //     return {
    //       visible: false
    //     }
    //   }
    // },
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '30%'
    }
  },
  data () {
    return {
      // show: false
    }
  },
  computed: {
    show: {       
      get:function(){
        return this.visible
      },
      set:function(val){
        this.$emit('update:visible', val)
      }
    }
  },
  mounted() {
  },
  methods: {
    moreChange(changeVal, changeKey) {
      this[changeKey].current = this[changeKey][changeVal]
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog{
  background: transparent;
}
/deep/ .el-dialog__header{
  padding: 0;
}
/deep/ .el-dialog__body{
  padding: 0;
}
/deep/ .el-dialog__headerbtn{
  font-size: 30px;
  z-index: 99;
}
</style>
