import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userData: {},
    patentType: [
      {
        name: '发明专利',
        code: 1
      },
      {
        name: '实用新型',
        code: 2
      },
      {
        name: '外观',
        code: 3
      }
    ],
    legalStatus: [
      {
        name: '申请状态',
        code: '0'
      },
      {
        name: '审查中状态',
        code: '1'
      },
      {
        name: '授权状态',
        code: '2'
      },
      {
        name: '有效状态',
        code: '3'
      },
      {
        name: '维护/年费缴纳状态',
        code: '4'
      },
      {
        name: '失效/过期状态',
        code: '5'
      },
      {
        name: '争议/法律挑战状态',
        code: '6'
      },
      {
        name: '再审查/无效宣告状态',
        code: '7'
      }
    ],
    status: [
      {
        name: '待审核',
        code: 0
      },
      {
        name: '上架',
        code: 1
      },
      {
        name: '已下架',
        code: 2
      },
      {
        name: '已售',
        code: 3
      },
      {
        name: '审核通过',
        code: 4
      },
      {
        name: '审核失败',
        code: 5
      }
    ],
    category: [
      { name: '专利新闻', code: 2, icon:'policy' },
      { name: '专利政策', code: 1, icon:'news' },
      { name: '专利问答', code: 3, icon:'qa' },
      { name: '实用工具', code: 4, icon:'policy' },
      { name: '常见问题', code: 5, icon:'qa' },
      { name: '关于我们', code: 7, icon:'qa' }
    ],
    seiBigClassNew: {
      1: ['智能制造装备产业','航空装备产业','卫星及应用产业','轨道交通装备产业','海洋工程装备产业'],
      2: ['下一代信息网络产业','电子核心产业','新兴软件和新型信息技术服务','互联网与云计算','大数据服务','人工智能']
    }
  },
  getters: {
    userData: state=>state.userData
  },
  mutations: {
    SET_USER(state, value) {
      state.userData = value
    }
  },
  actions: {
    setUserData({commit}, value) {
      commit('SET_USER', value)
    },
    getDictionaryName({state}, obj) {
      return new Promise((resolve)=>{
        const data = state[obj.key]
        var result;
        if (data) {
          for (var i = 0; i < data.length; i++) {
            if (data[i].code == obj.value) {
              result = data[i].name
              break
            }
          }
        }
        resolve(result)
      })
    },
    isLogin({state}) {
      return new Promise((resolve)=>{
        const data = state.userData.phone
        if (data) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
    }
  },
  modules: {

  }
}) 