<template>
  <div class="backTop">
    <el-backtop :bottom="340">
      <div class="Block">
        <div class="Pointer" @click="(e)=>e.stopPropagation()">
          <img src="@/assets/icons/customerService.png" />
        </div>
        <div class="backTop">
          <img src="@/assets/icons/backTop.png" />
        </div>
      </div>
    </el-backtop>
  </div>
</template>

<script>
export default {
  name: 'backTop',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      initialData: [
        {
          name: '首页',
          path: '/'
        }
      ]
    }
  },
  mounted() {},
  methods: {
    golink(link) {
      this.$router.push({ path: link })
    }
  },
}
</script>

<style scoped>
.backTop{}
.Block{
  width: 90px;
  height: 190px;
  padding: 10px;
}
.Block img{
  height: 90px;
}
.el-backtop{
  width: auto;
  height: auto;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
}
</style>