<template>
  <div class="header containerWidthMax UserSelect">
    <div class="containerWidth">
      <el-container>
        <div class="Block">
          <div class="logo Middle">
            <img alt="中和昇专利运营服务平台" src="@/assets/logo.png">
            <span>中和昇专利运营服务平台</span>
          </div>
          <div class="userBox">
            <div v-if="userData.name">
              <el-dropdown>
                <div class="dropdown Middle">
                  <el-avatar :size="30" :src="require('@/assets/head.png')">
                    <img src="@/assets/head.png" />
                  </el-avatar>
                  <span class="Le10">{{userData.name}}</span>
                  <span class="Le10"><i class="el-icon-arrow-down el-icon--right"></i></span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <p class="Middle" @click="golink('/personalCenter/personalInformation')">
                      <img src="@/assets/icons/personalCenter.png" />
                      <span class="Le10">个人中心</span>
                    </p>
                  </el-dropdown-item>
                  <!-- <el-dropdown-item>
                    <p class="Middle" @click="passwordVisible = true">
                      <img src="@/assets/icons/personalCenter.png" />
                      <span class="Le10">修改密码</span>
                    </p>
                  </el-dropdown-item> -->
                  <el-dropdown-item divided>
                    <p class="Middle" @click="exit">
                      <img src="@/assets/icons/exit.png" />
                      <span class="Le10">退出登录</span>
                    </p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <el-button-group v-else>
              <mainButton label="登录" @click="goLogin"></mainButton>
              <mainButton typeClass="secondary" label="注册" @click="goRegister"></mainButton>
            </el-button-group>
          </div>
        </div>
      </el-container>
      <div style="height: 20px;"></div>
      <el-menu
        class="el-menu-demo"
        mode="horizontal"
        :default-active="activeIndex"
        text-color="#172b44"
        active-text-color="#172b44"
        background-color="transparent"
        @select="handleSelect"
      >
        <el-menu-item v-for="item in menuData" :key="item.code" :index="item.code">
          {{ item.name }}
        </el-menu-item>
      </el-menu>
      <div class="line"></div>
    </div>
    <!-- 弹窗 -->
    <mainDialog :visible.sync="visible" width="885px">
      <div v-show="visibleType==1" class="loginBlock">
        <div class="imgBox">
          <img src="@/assets/login.png" />
        </div>
        <div class="inputArea">
          <p class="title">欢迎登录</p>
          <el-form ref="ruleForm" :rules="rules" :model="form" label-width="0">
            <el-form-item label="" prop="phone">
              <el-input v-model="form.phone" prefix-icon="el-icon-user" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <div style="height: 10px"></div>
            <el-form-item label="" prop="code">
              <el-input v-model="form.code" prefix-icon="el-icon-mobile-phone" placeholder="请输入验证码">
                <el-button type="primary" slot="append" :disabled="Boolean(timer)" @click="getCode(form.phone)">
                  <span class="white">{{getCodeName()}}</span>
                </el-button>
              </el-input>
            </el-form-item>
            <div style="height: 10px"></div>
            <el-button
              type="primary"
              style="width: 100%;font-size: 20px;"
              @click="verifyLogin"
              :loading="loading"
            >登 录</el-button>
            <p class="register" @click="goRegister">立即注册</p>
          </el-form>
        </div>
      </div>
      <div v-show="visibleType==2" class="registerBlock">
        <img src="@/assets/register.png" />
        <p class="title">注册</p>
        <div class="inputArea">
          <el-form ref="form" :rules="rules2" :model="registerForm" label-width="140px">
            <el-form-item label="手机号码" prop="phone">
              <el-input v-model="registerForm.phone" placeholder="请输入手机号码"></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model="registerForm.code" placeholder="请输入验证码">
                <el-button type="primary" slot="append" :disabled="Boolean(timer)" @click="getCode(registerForm.phone)">
                  <span class="white">{{getCodeName()}}</span>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input v-model="registerForm.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="年龄">
              <el-input v-model="registerForm.age" placeholder="请输入年龄"></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="registerForm.address" placeholder="请输入地址"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="registerForm.remark" placeholder="请输入备注"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                style="width: 100%;font-size: 20px;"
                type="primary"
                :loading="loading"
                @click="register"
              >立即注册</el-button>
              <div>
                <el-checkbox v-model="registerForm.checked">已阅读并同意以下协议<a class="blue" href="#">服务条款、隐私协议</a></el-checkbox>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </mainDialog>
    <BackTop></BackTop>
    <!-- <PasswordWindow :visible.sync="passwordVisible" title="修改密码" :row="$store.state.userData"></PasswordWindow> -->
  </div>
</template>

<script>
import mainButton from '@/components/control/button'
import mainDialog from '@/components/control/dialog'
import BackTop from '@/components/backTop.vue'
// import PasswordWindow from '@/components/passwordWindow'
import { isValidPhoneNumber, elPhoneValidate } from '@/assets/js/verification.js'
import { setCookie,getCookie,deleteCookie } from '@/assets/js/tool'
export default {
  name: 'mainHeader',
  components: {
    mainButton,
    mainDialog,
    BackTop
    // PasswordWindow
  },
  data () {
    return {
      visible: false,
      visibleType: 1,
      userData: {
        name: ''
      },
      form: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: elPhoneValidate, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 6, message: '长度在 4 到 6 个字符', trigger: 'blur' }
        ]
      },
      rules2: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: elPhoneValidate, trigger: 'blur' }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 6, message: '长度在 4 到 6 个字符', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          { min: 2, max: 18, message: '长度在 2 到 18 个字符', trigger: 'blur' }
        ]
      },
      registerForm: {
        phone: '',
        code: '',
        name: '',
        age: '',
        address: '',
        remark: ''
      },
      menuData: [
        {
          name: '首页',
          code: '/home'
        },
        {
          name: '专利商城',
          code: '/shopping'
        },
        {
          name: '专利数据库',
          code: '/database'
        },
        {
          name: '专利交易流程',
          code: '/transactionProcess'
        },
        {
          name: '专利申请',
          code: '/patentApplication'
        },
        {
          name: '托管服务',
          code: '/hostedServices'
        },
        {
          name: '关于我们',
          code: '/aboutUs'
        },
      ],
      activeIndex: '/home',
      codeNum: 0,
      timer: null,
      loading: false,
      passwordVisible: false
    }
  },
  watch: {
    '$route': {
      // handler: function (to, from) {
      handler: function () {
        // 执行你想要的任何操作
        // console.log('路由已改变：', from.path, '->', to.path);
        this.menuSelection()
      },
      // 如果需要在页面刷新时也能监听到路由变化，需要使用immediate和deep选项
      // immediate: true,
      // deep: true
    }
  },
  mounted() {
    this.menuSelection()
    this.getUserData()
  },
  methods: {
    getCodeName() {
      if (this.codeNum) {
        return this.codeNum + '秒'
      } else {
        return '获取验证码'
      }
    },
    getCode(cellPhone) {
      cellPhone = cellPhone+''
      if (!isValidPhoneNumber(cellPhone)) return this.$message.error('请输入正确的11位手机号')
      const key =  Number(sessionStorage.getItem('cellPhone'))
      const current = new Date().getTime()
      const difference = current - key
      if (difference >= 60000) {
        this.codeNum = 60;
        sessionStorage.setItem('cellPhone', current);
        this.post('/sms/sendSmsCode?phone=' + cellPhone).catch(()=>{
          this.codeNum = 10;
          sessionStorage.removeItem('cellPhone');
        })
      } else {
        this.codeNum = parseInt(60-difference/1000);
        this.$message.warning('发送验证码间隔60秒')
      }
      if (!this.timer) this.timer = setInterval(()=>{
        if (this.codeNum > 0) {
          this.codeNum--
        } else {
          clearInterval(this.timer)
          this.timer = null
        }
      }, 1000)
    },
    menuSelection() {
      this.activeIndex = window.location.pathname?window.location.pathname:''
    },
    verifyLogin(){
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.getToken()
        } else {
          return false;
        }
      });
    },
    register() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.registerForm.checked) return this.$message.warning('您还没有勾选协议')
          this.loading = true
          this.post('/front/register', this.registerForm).then(e=>{
            setCookie('token', e.data.jwt, 1)
            this.getUserData(e.data.jwt)
          }).catch(()=>{
            this.loading = false
          })
        } else {
          return false;
        }
      });
    },
    getToken(){
      this.post('/auth/smsCodeLogin', { get:this.form }).then(e=>{
        setCookie('token', e.data.jwt, 1)
        this.getUserData(e.data.jwt)
      }).catch(()=>{
        this.loading = false
      })
    },
    getUserData(token){
      if (!token) token = getCookie('token')
      if (!token) return
      this.get('/api/user/userinfo').then(e=>{
        this.userData.name = e.data.nickname
        this.$store.dispatch('setUserData', e.data)
        this.visible = false
      }).finally(()=>{
        this.loading = false
      })
    },
    exit(){
      this.userData.name = ''
      this.$store.dispatch('setUserData', {})
      deleteCookie('token');
      this.golink('/home')
    },
    goLogin() {
      this.visibleType = 1
      this.visible = true
      this.$nextTick(() => {
        this.$refs.ruleForm.resetFields()
      });
    },
    goRegister() {
      this.visibleType = 2
      this.visible = true
    },
    handleSelect(key, keyPath) {
      this.$router.push({ path: keyPath[0] })
    },
    golink(link) {
      this.$router.push({ path: link })
    }
  },
  destroyed() {
    this.timer && clearInterval(this.timer)
  }
}
</script>

<style scoped>
.Block{
  margin-top: 30px;
  width: 100%;
}
.header{
  background: rgba(255, 255, 255, 0.5);
}
.logo{
  float: left;
}
.userBox{
  margin-top: 15px;
  float: right;
  height: 30px;
}
.logo{
  color: #0768c2;
  font-size: 24px;
  line-height: 52px;
  font-weight: 900;
}
.logo img{
  margin-right: 10px;
}
/*/deep/ .is-active{*/
.is-active{
  font-weight: 900;
}
.el-menu-item{
  padding: 0 10px;
  margin: 0 40px;
  font-size: 16px;
}
/*/deep/.el-submenu__title:hover {
  color:white!important;
  background: #1890FF!important;
}*/
.el-menu-item:hover {
  font-weight: 900;
  background: transparent !important;
}
/*<!-- 弹窗 -->*/
.loginBlock{
  border-radius: 10px;
  height: 484px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.7);
  background: #fff;
}
.loginBlock .imgBox{
  float: left;
}
.loginBlock .inputArea{
  float: right;
  padding: 80px 40px 0 0;
  width: 345px;
}
.loginBlock .title{
  font-size: 30px;
  font-weight: 900;
  color: #247bcc;
  margin-bottom: 30px;
}
.register{
  color: #247bcc;
  font-weight: 900;
  text-align: right;
  cursor: pointer;
  margin-top: 20px;
}

.registerBlock{
  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.registerBlock .title{
  font-size: 30px;
  font-weight: 900;
  color: #247bcc;
  position: absolute;
  top: 60px;
  left: 40px;
  z-index: 1;
  width: 100%;
  text-align: center;
}
.registerBlock .inputArea{
  padding: 30px 140px;
  text-align: left;
}
.inputArea /deep/ .el-button{
    background: #247bcc;
}
.white{
  color: #fff;
}
.blue{
  color: #247bcc;
}
/*用户信息*/
.dropdown{
  font-size: 20px;
  cursor: pointer;
  color: #034da3;
}
</style>
