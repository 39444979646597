import { Message,MessageBox } from 'element-ui'
import { getCookie,deleteCookie } from '@/assets/js/tool'
const proxyPath = '/api'
/**
 * [formatParams 对象属性转get地址参数]
 * @description [description]
 * @Author      zzk
 * @EditTime    2024-03-27T15:36:30+0800
 * @param       {[type]}                 data [转换前对象]
 * @return      {[type]}                      [description]
 */
const formatParams = function(data) {
  if (data) {
    var arr = [];
    for (var name in data) {
      arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
    }
    return arr.join('&');
  } else {
    return '';
  }
}
const customizeFetch = function(config) {
	var promise = new Promise((resolve,reject) => {
		fetch(config.url, config).then(response => {
			if (response.ok) {
				return response.json()
			} else if (response.status == 403) {
				deleteCookie('token')
				MessageBox.alert('请登录后再进行操作', '提示', {
					confirmButtonText: '返回',
					callback:()=>{
					deleteCookie('token')
					location.reload()
					}
				})
			} else {
				const msg = '数据读取异常'
				Message.error(msg)
				reject(msg)
			}
		}).then(json => {
			if (json&&json.code == 200) {
				resolve(json)
			} else {
				if (json&&json.msg) Message.error(json.msg)
				reject()
			}
		}).catch(err => {
			reject(err)
			throw err
		})
	})
	return promise
}
const get = function(address, obj) {
  const config = {
		method: 'get',
		headers: {
			'Content-Type': 'application/json'
		}
	}
	const token = getCookie('token')
	if (token) config.headers.Authorization = 'Bearer ' + token
	config.url = proxyPath + address
	if(obj) {
		config.url = config.url + '?' + formatParams(obj)
	}
	return customizeFetch(config)
}
const post = function(address, obj) {
	const config = {
		method: 'post',
		headers: {
			'Content-Type': 'application/json'
		}
	}
	config.url = proxyPath + address
	const token = getCookie('token')
	if (token) config.headers.Authorization = 'Bearer ' + token
	if(obj) {
		if (obj.get) {
			config.url = config.url + '?' + formatParams(obj.get)
		} else {
			config.body = JSON.stringify(obj)
		}
	}
	return customizeFetch(config)
}
const put = function(address, obj) {
  const config = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json'
		}
	}
	const token = getCookie('token')
	if (token) config.headers.Authorization = 'Bearer ' + token
	config.url = proxyPath + address
	if(obj) {
		if (obj.get) {
			config.url = config.url + '?' + formatParams(obj.get)
		} else {
			config.body = JSON.stringify(obj)
		}
	}
	return customizeFetch(config)
}
const formData = function(address, obj) {
	const config = {
		method: 'post',
		headers: {}
	}
	config.url = proxyPath + address
	const token = getCookie('token')
	if (token) config.headers.Authorization = 'Bearer ' + token
	if(obj) {
		var formData = new FormData()
		for(let key in obj) {
			formData.append(key, obj[key])
		}
		config.body = formData
	}
	return customizeFetch(config)
}
const getImg = function(address) {
	var promise = new Promise((resolve,reject) => {
		const msg = '404'
		fetch(address).then(response => {
			if (response.ok) {
				return response.blob()
			} else {
				reject(msg)
			}
		}).then(blob => {
			if (blob) {
				resolve(blob)
			} else {
				reject(msg)
			}
		}).catch(err => {
			reject(err)
		})
	})
	return promise
}
const use = {
	install: function(Vue){
		Vue.prototype.post = post
		Vue.prototype.get = get
		Vue.prototype.put = put
		Vue.prototype.formData = formData
		Vue.prototype.getImg = getImg
	}
}

export default use