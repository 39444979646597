import { ref } from 'vue'
/**
 * [useDefer 延迟加载解决长时间白屏]
 * @description [description]
 * @Author      zzk
 * @EditTime    2024-03-27T15:34:32+0800
 * @param       {Number}                 maxCount [循环最大帧数]
 * @return      {[type]}                          [是否大于等于当前帧]
 */
export const useDefer = function(maxCount = 99) {
	const frameCount = ref(1)
	let rafId;
	function updateFrameCount() {
		rafId = requestAnimationFrame(() => {
			frameCount.value++
			if (frameCount.value >= maxCount) return
			updateFrameCount()
		})
	}
	function destroyed() {
		cancelAnimationFrame(rafId)
	}
	updateFrameCount()
	return function(n, isDestroyed) {
		if (isDestroyed) destroyed()
		return frameCount.value >= n
	}
}
/**
 * [formatParams 对象属性转get地址参数]
 * @description [description]
 * @Author      zzk
 * @EditTime    2024-03-27T15:36:30+0800
 * @param       {[type]}                 data [转换前对象]
 * @return      {[type]}                      [description]
 */
export const formatParams = function(data) {
  if (data) {
    var arr = [];
    for (var name in data) {
      arr.push(encodeURIComponent(name) + '=' + encodeURIComponent(data[name]));
    }
    return arr.join('&');
  } else {
    return '';
  }
}
/**
 * [getQueryVariable 获取地址栏参数]
 * @description [description]
 * @Author      zzk
 * @EditTime    2024-03-27T15:38:02+0800
 * @param       {[type]}                 variable [参数属性]
 * @return      {[type]}                          [参数值]
 */
export const getQueryVariable = function(variable) {
  var query = window.location.search.substring(1)
  var vars = query.split("&")
  var i,pair
	if (variable) {
		for (i = 0; i < vars.length; i++) {
			pair = vars[i].split("=");
			if (pair[0] == variable) {
				return decodeURI(pair[1]);
			}
		}
  } else {
		var obj = {}
		for (i = 0; i < vars.length; i++) {
			pair = vars[i].split("=")
			if (pair[0]) obj[pair[0]] = decodeURI(pair[1])
		}
		return obj
  }
  return;
}
/**
 * [getDate 获取日期]
 * @description [description]
 * @Author      zzk
 * @EditTime    2024-04-11T11:13:49+0800
 * @param       {[type]}                 time [有转换成年月日，无当前时间]
 * @return      {[type]}                      [年月日]
 */
export const getDate = function(time) {
	var date
	if (time) {
		date = new Date(time)
	} else {
		if (time === undefined) {
			date = new Date()
		} else {
			return ''
		}
	}
	const year = date.getFullYear()
	const month = date.getMonth() + 1
	const day = date.getDate()
	return year + '-' + month + '-' + day
}
export const getDictionaryName = function(data, value) {
    var result;
    if (data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].code == value) {
          result = data[i].name
          break
        }
      }
    }
    return result
}
/**
 * [getUrl 获取专利号图片地址]
 * @description [description]
 * @Author      zzk
 * @EditTime    2024-04-11T21:37:50+0800
 * @param       {[type]}                 patentNumber [专利号]
 * @return      {[type]}                              [description]
 */
export const getUrl = function(patentNumber) {
  return '/api/pic/' + patentNumber + '.jpg'
}
/**
 * [priceUnit 价格单位]
 * @description [description]
 * @Author      zzk
 * @EditTime    2024-05-07T14:35:03+0800
 * @param       {[type]}                 val [值]
 * @return      {[type]}                     [description]
 */
export const priceUnit = function(val) {
	val = val + ''
	if (!val) {
		return ''
	} else if (Number(val) >= 0 ) {
		return '￥'+val
	} else {
		return val
	}
}

export const setCookie = function(name, value, expires, path, domain, secure) {
    let cookieString = name + "=" + encodeURIComponent(value);
    if (expires) {
        let expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + expires * 24 * 60 * 60 * 1000);
        cookieString += "; expires=" + expirationDate.toUTCString();
    }
    if (path) cookieString += "; path=" + path;
    if (domain) cookieString += "; domain=" + domain;
    if (secure) cookieString += "; secure";
    document.cookie = cookieString;
}
export const getCookie = function(name) {
    let cookieName = name + "=";
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i].trim();
        if (cookie.indexOf(cookieName) === 0) {
            return decodeURIComponent(cookie.substring(cookieName.length));
        }
    }
    return null;
}
export const deleteCookie = function(name, path, domain) {
    if (getCookie(name)) document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
    if (path) document.cookie = name + "=; path=" + path;
    if (domain) document.cookie = name + "=; domain=" + domain;
}