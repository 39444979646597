<template>
  <span>
    <!-- <el-button v-bind="$attrs"></el-button> -->
    <template v-if="['more','viewDetails'].includes(typeClass)">
      <div class="moreBtn"
        @mouseenter="moreChange('active', typeClass)"
        @mouseleave="moreChange('default', typeClass)"
        @click="$emit('click')"
      >
        <img :src="urlJoin(typeClass)" />
      </div>
    </template>
    <template v-else>
      <el-button :class="typeClass" type="primary" size="mini" @click="$emit('click')">
        {{ label }}
        <template v-for="(value, name) in $slots" #[name]="slotData">
          <slot :name="name" v-bind="slotData || {}"></slot>
        </template>
      </el-button>
    </template>
  </span>
</template>

<script>
import more from '@/assets/icons/more.png'
import moreA from '@/assets/icons/moreActive.png'
import viewDetails from '@/assets/icons/viewDetails.png'
import viewDetailsA from '@/assets/icons/viewDetailsActive.png'
export default {
  name: 'mainButton',
  props: {
    label: {
      type: String,
      default: '按钮'
    },
    typeClass: {
      type: String,
      default: 'primary'
    }
  },
  data () {
    return {
      more: {
        current: more,
        default: more,
        active: moreA
      },
      viewDetails: {
        current: viewDetails,
        default: viewDetails,
        active: viewDetailsA
      }
    }
  },
  mounted() {
    // const entries = Object.entries(this.$refs.but)
    // console.log(entries)
    // var index = 0;
    // for (const [key, value] of entries) {
    //   console.log(key, value);
    //   index++;
    //   if (index >= 35) return
    //   this[key] = value
    // }
  },
  methods: {
    urlJoin(str) {
      return this[str].current
    },
    moreChange(changeVal, changeKey) {
      this[changeKey].current = this[changeKey][changeVal]
    }
  }
}
</script>

<style scoped>
.primary{
  color: #034ea4;
  background: #c6e0ff;
  border-color: #c6e0ff;
}
.secondary{
  color: #fff;
  background: #034ea4;
  border-color: #034ea4;
}
.moreBtn{
  cursor: pointer;
}
</style>
