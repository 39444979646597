<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.containerWidth{
  width: 1200px;
  margin: 0 auto;
}
.containerWidthMin{
  width: 100%;
  min-width: 1200px;
}
.containerWidthMax{
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.Block{
  overflow: hidden;
  position: relative;
  clear: both;
}
.Le{
  float: left;
}
.Ri{
  float: right;
}
.Middle img, .Middle span{
  vertical-align: middle;
}
.CX{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.CY{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ellipsis2{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.ellipsis3{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.Pointer{
  cursor: pointer;
}
.UserSelect{
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* Standard syntax */
}
.Le10{
  margin-left: 10px;
}

/* 定义动画 */
@keyframes moveRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
 
/* 应用动画到元素 */
.move-right {
/*  animation: moveRight 2s infinite alternate;*/
  animation: moveRight 2s alternate;
}
</style>
